import { graphql, navigate } from 'gatsby';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import CircleFlag from '../components/language-switcher/CircleFlag';
import Link from '../components/link/Link';
import Translated from '../components/translation/Translated';
import { useDetectLocale } from '../hooks/useDetectLocale';

const LanguageSelectionPage = ({ data }) => {
  const availableLocales: string[] = data.allStrapiPage.distinct;

  const [browserLocale, setBrowserLocale] = useState<string>(null);
  const [preferredLocales, setPreferredLocales] = useState<readonly string[]>(
    []
  );

  useDetectLocale();

  useEffect(() => {
    setPreferredLocales(navigator.languages.map((lang) => lang.split('-')[0]));
    setBrowserLocale(navigator.language);

    availableLocales.sort((a, b) => {
      if (preferredLocales.includes(a) && preferredLocales.includes(b)) {
        return 0;
      }
      if (preferredLocales.includes(a)) {
        return 1;
      }
      if (preferredLocales.includes(b)) {
        return -1;
      }
      return 0;
    });

    if (availableLocales.includes(navigator.language)) {
      navigate(`/${navigator.language}/`);
    }
    if (availableLocales.includes(navigator.language.split('-')[0])) {
      navigate(`/${navigator.language.split('-')[0]}/`);
    }
  }, []);

  return (
    <div className="flex flex-col grow justify-center items-center bg-slate-100 dark:bg-slate-900 sm:p-16 p-4">
      <div className="text-center sm:text-6xl text-3xl font-bold mb-2 text-black dark:text-white">
        <Translated ns="languages" k="welcome" />
      </div>
      <div className="text-center mb-12 sm:text-2xl text-xl font-bold text-slate-400 dark:text-slate-500">
        <Translated ns="languages" k="selectionMessage" />
      </div>
      <div className="flex justify-center flex-wrap gap-16">
        {availableLocales.map((locale: string) => (
          <Link key={locale} to={`/${locale}`} className={`block`}>
            <CircleFlag
              className="sm:w-32 sm:h-32 w-16 h-16 mb-4 mx-auto"
              countryCode={locale}
            />
            <div className="text-center sm:text-xl text-lg font-bold text-black dark:text-white">
              <Translated ns="languages" k={locale} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export const query = graphql`
  query LanguageSelectionPageQuery {
    allStrapiPage {
      distinct(field: locale)
    }
  }
`;

export default LanguageSelectionPage;
